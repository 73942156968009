<div>
    <ul class="layout-menu">
        <div *ngIf="showHeader" id="menu-header" class="font-bold text-3xl text-primary">
            <div class="flex flex-row gap-3 align-items-center py-5 px-3" >
                <div id="organization-logo">
                    <div *ngIf="menuService?.loading === true">
                        <div class=" flex align-items-center justify-content-center w-4rem h-4rem border-round">
                            <p-progressSpinner styleClass="w-3rem h-3rem alpha-spinner block" strokeWidth="8"
                                fill="#ffffff" animationDuration=".5s"></p-progressSpinner>
                        </div>
                    </div>
                    <img *ngIf="organizationLogo != null" src="{{organizationLogo}}" class="h-4rem border-round">
                </div>
                <span *ngIf="organization != null" class="text-4xl">
                    {{ organization.name }}
                </span>
                <span *ngIf="user?.role_name == 'cluster-admin' && organization == null" class="text-4xl">
                    {{ user?.cluster?.name }}
                </span>
            </div>
            
            <div *ngIf="showBackButton()" class="pb-4 px-3">
                <p-button
                    label="{{ 'LABELS.back-to-organizations'| translate }}"
                    severity="primary" outlined="true"
                    icon="pi pi-arrow-left"
                    (click)="backToMainMenu()"
                    styleClass="w-full">
                </p-button>
            </div>
        </div>
        <div *ngIf="showHeader" class="px-3">
            <p-divider></p-divider>
        </div>
        <div class="px-3">
            <ng-container *ngFor="let item of model; let i = index;" >
                <li app-menuitem *ngIf="!item.separator" [item]="item" [index]="i" [root]="true"></li>
                <li *ngIf="item.separator" class="menu-separator"></li>
            </ng-container>
        </div>
    </ul>
</div>

